<template>
    <div>
        <ValidationObserver ref="staffForm">
            <div class="row">
                <div class="col-12">
                    <ValidationProvider name="tcNo" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('tc_no')">
                            <b-form-input
                                v-model="staffForm.tcNo"
                                :state="errors[0] ? false : (valid ? true : null)">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="gsmNo" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('gsm_no')">
                            <b-form-input
                                v-model="staffForm.gsmNo"
                                v-mask="'(+49)-##-########'"
                                placeholder="(+49)-##-########"
                                :state="errors[0] ? false : (valid ? true : null)">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <b-button
                        @click="submitStaffForm"
                        type="submit"
                        variant="primary"
                        class="btn-lg btn-block d-flex justify-content-between align-items-center"
                    >{{ $t('send_password') }}
                    </b-button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import AuthLayout from "@/layouts/AuthLayout"
    import AuthService from "@/services/AuthService";
    import OtpInput from "@bachdgvn/vue-otp-input";
    import moment from "moment";

    export default {
        components: {
            AuthLayout,
            OtpInput,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                staffForm: {
                    tcNo: '',
                    gsmNo: ''
                },

            };
        },
        methods: {
            onCaptchaVerified: function (recaptchaToken) {
                let formData = {
                    type: 'staff',
                    national_id: this.staffForm.tcNo,
                    mobile_phone: this.staffForm.gsmNo.replace(/[()-]/g,''),
                    g_recaptcha_token: recaptchaToken
                }
                AuthService.passwordReset(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$store.commit('auth/setActivePage', 'login');
                    })
                    .catch(error => {
                        if (error.status == 422) {
                            if (error.data.errors.national_id) {
                                this.$refs.staffForm.errors.tcNo.push(error.data.errors.national_id[0]);
                            }
                            if (error.data.errors.mobile_phone) {
                                this.$refs.staffForm.errors.gsmNo.push(error.data.errors.mobile_phone[0]);
                            }
                        }
                        else if (error.status == 406) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
            },
            async submitStaffForm() {
                const isValid = await this.$refs.staffForm.validate();
                if (isValid) {
                    this.$emit('captcha', 'resetPasswordStaff');
                }
            },

        },
    };
</script>
