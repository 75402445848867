<template>
    <div>
        <ValidationObserver ref="alumniForm">
            <div class="row">
                <div class="col-12">
                    <ValidationProvider name="tcNo" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('tc_no')">
                            <b-form-input
                                v-model="graduationForm.tcNo"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="diplomaNumber" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('diploma_number')">
                            <b-form-input
                                v-model="graduationForm.diplomaNumber"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="graduationDate" rules="required" v-slot="{valid, errors}">
                        <b-form-group
                            :label="$t('graduation_date')"
                            class="position-relative"
                        >
                            <v-date-picker
                                v-model="graduationForm.graduationDate"
                                :locale="$i18n.locale"
                                is-expanded
                                :masks="masks"
                                :max-date="new Date()"
                                :popover="{ 'visibility': 'click' }"
                            >
                                <template
                                    v-slot="{ inputValue, inputEvents }">
                                    <b-input-group>
                                        <b-form-input
                                            :readonly="true"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <b-input-group-append>
                                            <b-button
                                                variant="outline-secondary"
                                                class="btn-40"
                                                disabled>
                                                <i class="ri-calendar-line"></i>
                                            </b-button>
                                        </b-input-group-append>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-input-group>
                                </template>
                            </v-date-picker>

                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <b-button
                        @click="submitGraduationForm"
                        type="submit"
                        variant="primary"
                        class="btn-lg btn-block d-flex justify-content-between align-items-center">
                        {{ $t('send_password') }}
                    </b-button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import AuthService from "@/services/AuthService";
    import moment from "moment";

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {

                graduationForm: {
                    tcNo: '',
                    diplomaNumber: '',
                    graduationDate: ''
                },

                masks: {
                    input: "DD-MM-YYYY",
                },

            };
        },
        methods: {
            onCaptchaVerified: function (recaptchaToken) {
                this.graduationForm.graduationDate = moment(this.graduationForm.graduationDate).format('YYYY-MM-DD');

                let alumniForm = {
                    type: 'alumni',
                    national_id: this.graduationForm.tcNo,
                    diploma_number: this.graduationForm.diplomaNumber,
                    graduation_date: this.graduationForm.graduationDate,
                    g_recaptcha_token: recaptchaToken
                };

                AuthService.passwordReset(alumniForm)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$store.commit('auth/setActivePage', 'login');
                    })
                    .catch(error => {
                        if (error.status == 422) {
                            if (error.data.errors.national_id) {
                                this.$refs.alumniForm.errors.tcNo.push(error.data.errors.national_id);
                            }
                            if (error.data.errors.diploma_number) {
                                this.$refs.alumniForm.errors.diplomaNumber.push(error.data.errors.diploma_number);
                            }
                            if (error.data.errors.graduation_date) {
                                this.$refs.alumniForm.errors.graduationDate.push(error.data.errors.graduation_date);
                            }
                        }
                        else if (error.status == 406) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
            },
            async submitGraduationForm() {
                const isValid = await this.$refs.alumniForm.validate();
                if (isValid) {
                    this.$emit('captcha', 'resetPasswordAlumni');
                }
            },

        },
    };
</script>
